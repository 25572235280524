import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {BarDatum, ResponsiveBar} from "@nivo/bar";
import {useVisibleTrackingColumns} from "../lib/trackingColumns";
import {CartesianMarkerProps, DatumValue} from "@nivo/core";
import {camelCase, get, omit, snakeCase} from "lodash";
import {ProductLabels} from "../lib/testTypes";
import {useFormatAnalyte} from "../lib/analytes";
import {RENDER_BASE} from "../lib/assets";

// types
import {
  SingleAnalyteGroupReportsQueryVariables,
  useSingleAnalyteGroupReportsLazyQuery,
  useGetApplicationSettingsQuery,
  SampleTestType,
  SampleStage,
  SampleSummaryFieldsFragment,
  HealthProfileFragment,
  SingleAnalyteGroupReportFieldsFragment,
} from "../generated/graphql";

// components
import {Loading} from "../components/Loading";
import FullPageLayout from "../components/Layouts/FullPageLayout";
import SampleFilterCard, {
  useSampleFilterVariables,
} from "../components/SampleFilterCard";

// mui
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Tooltip from "@mui/material/Tooltip";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import ListSubheader from "@mui/material/ListSubheader";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ViewCozy from "@mui/icons-material/ViewCozy";
import { useProfile } from "../components/Auth/ProfileProvider";

type MenuOptions = {
  value: string;
  label?: string;
};

const XAXIS_OPTIONS = [
  {value: "collection_date", label: "Collection Date"},
  {value: "days_in_stage", label: "Days In Stage"},
] as MenuOptions[];

type ReportInsight = {
  title: string;
  icon: React.ReactNode;
  tooltip: string;
  variables: SingleAnalyteGroupReportsQueryVariables;
};

const insights: ReportInsight[] = [
  {
    title: "Cultivar Nitrogen Over Time",
    icon: <ViewCozy/>,
    tooltip: "See how your plant tissue is absorbing nutrients over time",
    variables: {
      filters: {testType: SampleTestType.PlantTissue, stage: ""},
      groupingColumn: "cultivar",
      xAxis: "collection_date",
      analyte: "result_nitrogen",
    },
  },
  {
    title: "Cultivar Nitrogen - Veg",
    icon: <ViewCozy/>,
    tooltip: "Examine Plant Tissue Nitrogen levels in Vegetation stage",
    variables: {
      filters: {
        testType: SampleTestType.PlantTissue,
        stage: SampleStage.Veg,
      },
      groupingColumn: "cultivar",
      xAxis: "days_in_stage",
      analyte: "result_nitrogen",
    },
  },
  {
    title: "Cultivar Nitrogen - Flower",
    icon: <ViewCozy/>,
    tooltip: "Examine Plant Tissue Nitrogen levels in Flower",
    variables: {
      filters: {
        testType: SampleTestType.PlantTissue,
        stage: SampleStage.Flower,
      },
      groupingColumn: "cultivar",
      xAxis: "days_in_stage",
      analyte: "result_nitrogen",
    },
  },
  {
    title: "Cultivar Nitrogen - Late Flower",
    icon: <ViewCozy/>,
    tooltip: "Examine Plant Tissue Nitrogen levels in Late Flower",
    variables: {
      filters: {
        testType: SampleTestType.PlantTissue,
        stage: SampleStage.LateFlower,
      },
      groupingColumn: "cultivar",
      xAxis: "days_in_stage",
      analyte: "result_nitrogen",
    },
  },
];

export const Analytics = () => {
  const trackingColumns = useVisibleTrackingColumns();
  const [params] = useSearchParams();
  const [filterVars, addParams] = useSampleFilterVariables();
  const profile = useProfile()

  // TODO Let them choose a health profile
  const healthProfile = profile.lab?.healthProfiles?.[0];
  
  const applicationSettings =
    useGetApplicationSettingsQuery()?.data?.getApplicationSettings;

  const variables = {
    groupingColumn: params.get("groupingColumn") || "",
    analyte: params.get("analyte") || "",
    xAxis: params.get("xAxis") || "collection_date",
    filters: omit(filterVars, ["analyte", "groupingColumn", "xAxis"]),
  } as SingleAnalyteGroupReportsQueryVariables;

  const testAnalytes = applicationSettings?.testAnalytes
    .find((t) => t.testType === variables.filters?.testType)
    ?.analytes.map((key) =>
      applicationSettings.analytes.find((a) => a.id === key)
    );

  const [execute, queryResult] = useSingleAnalyteGroupReportsLazyQuery();
  const formatAnalyte = useFormatAnalyte();
  
  const report = queryResult?.data?.singleAnalyteGroupReports;

  function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (variables.groupingColumn && variables.analyte && variables.xAxis) {
      execute({variables});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variables)]);

  useEffect(() => {
    if (testAnalytes && testAnalytes.length > 0 && !testAnalytes.find(a => a?.id === variables.analyte)) {
      addParams({analyte: `result_${testAnalytes[0]?.id}`});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables.filters?.testType]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const analyte = variables.analyte
    ? capitalizeFirstLetter(variables.analyte.replace("result_", ""))
    : "";

  const analyteLabel = variables.analyte && <div
      dangerouslySetInnerHTML={{__html: testAnalytes?.find(a => a?.id === variables.analyte.replace("result_", ""))?.label || ""}}/>

  const getAnalyteValue = (row: SampleSummaryFieldsFragment) =>
    parseFloat(row[camelCase(`result_${analyte}`) as keyof typeof row]);

  return (
    <FullPageLayout>
      <Box textAlign="center">
        <h1>Analytics</h1>
      </Box>

      <Card sx={{marginBottom: "1em"}}>
        <CardHeader
          title="Analytics Insights"
          subheader="Shortcuts to key analytics views"
        />
        <CardContent>
          <Grid container spacing={1}>
            {insights.map(
              ({
                 title,
                 icon,
                 tooltip,
                 variables: {filters, ...options},
               }) => (
                <Grid item xs={6} sm={4} md={3} style={{textAlign: "center"}}>
                  <Tooltip title={tooltip}>
                    <Button
                      startIcon={icon}
                      variant="outlined"
                      onClick={() => addParams({...options, ...filters})}
                      color={
                        Object.entries(filters || {}).every(
                          ([key, value]) =>
                            (value || "") === (params.get(key) || "")
                        ) &&
                        Object.entries(options || {}).every(
                          ([key, value]) =>
                            (value || "") === (params.get(key) || "")
                        )
                          ? "primary"
                          : "secondary"
                      }
                    >
                      <Typography variant="body2">{title}</Typography>
                    </Button>
                  </Tooltip>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{marginBottom: "1em"}}>
        <CardHeader title={"Options"}/>
        <CardContent style={{paddingTop: 0}}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item>
              <TextField
                select
                sx={{minWidth: "200px"}}
                label="Group By"
                value={variables.groupingColumn}
                onChange={(e) => addParams({groupingColumn: e.target.value})}
              >
                {(trackingColumns || [])
                  .filter((column) => column !== "daysInStage")
                  .map((opt) => (
                    <MenuItem value={snakeCase(opt)} key={opt}>
                      {capitalizeFirstLetter(snakeCase(opt).replace("_", " "))}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item>
              <FormControl sx={{minWidth: 200}}>
                <InputLabel htmlFor="analyte-select">Analyte</InputLabel>
                <Select
                  id="analyte-select"
                  label="Analyte"
                  value={variables.analyte}
                  onChange={(e) => addParams({analyte: e.target.value})}
                >
                  {filterVars.testType === SampleTestType.HeavyMetal ?
                    testAnalytes && [
                      <ListSubheader>Heavy metals</ListSubheader>,
                      ...testAnalytes?.map(
                        (analyte) =>
                          (analyte &&
                              <MenuItem
                                  value={`result_${analyte.id}`}
                                  key={analyte.id}
                              >
                                  <div dangerouslySetInnerHTML={{__html: analyte.label}}/>
                              </MenuItem>
                          ))
                    ]
                    :
                    testAnalytes?.length && [
                      <ListSubheader>Primary</ListSubheader>,
                      ...testAnalytes?.map(
                        (analyte) =>
                          analyte?.group === "primary" && (
                            <MenuItem
                              value={`result_${analyte.id}`}
                              key={analyte.id}
                            >
                              <div dangerouslySetInnerHTML={{__html: analyte.label}}/>
                            </MenuItem>
                          )
                      ),
                      <ListSubheader>Secondary</ListSubheader>,
                      ...testAnalytes?.map(
                        (analyte) =>
                          analyte?.group === "secondary" && (
                            <MenuItem
                              value={`result_${analyte.id}`}
                              key={analyte.id}
                            >
                              {analyte.label}
                            </MenuItem>
                          )
                      ),
                      <ListSubheader>Micro</ListSubheader>,
                      ...testAnalytes?.map(
                        (analyte) =>
                          analyte?.group === "micro" && (
                            <MenuItem
                              value={`result_${analyte.id}`}
                              key={analyte.id}
                            >
                              {analyte.label}
                            </MenuItem>
                          )
                      )
                    ]
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                select
                sx={{minWidth: "200px"}}
                label="X Axis"
                value={variables.xAxis}
                onChange={(e) => addParams({xAxis: e.target.value})}
              >
                {XAXIS_OPTIONS.map((opt) => (
                  <MenuItem value={opt.value} key={opt.value}>
                    {opt.label || opt.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <SampleFilterCard analytics/>

      {queryResult?.loading ? (
        <Loading/>
      ) : (
        report && (
          ([healthProfile ]).map(profile =>
            <GraphWithHealthProfile analyte={analyte}
                                    report={report}
                                    profile={variables.filters?.stage ? profile : undefined}
                                    title={<>
                                      {analyteLabel} by {variables.groupingColumn.replace("_", " ")}
                                    </>}
            />
          )
        ))}

      <Card sx={{padding: "1em", margin: "1em 0"}}>
        <TableContainer>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Test Type</TableCell>
                <TableCell>{analyteLabel}</TableCell>
                <TableCell>Results</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryResult.data?.singleAnalyteGroupReports?.samples
                .slice()
                .sort((a, b) => getAnalyteValue(b) - getAnalyteValue(a))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  (row) =>
                    row && (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.label}</TableCell>
                        <TableCell>
                          {row.testType
                            ? get(ProductLabels, row.testType)
                            : null}
                        </TableCell>
                        <TableCell>
                          {formatAnalyte(analyte, getAnalyteValue(row))}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Open PDF" arrow placement="top">
                            <a
                              href={RENDER_BASE + `api/render/sample_certificate-${row.id}.pdf`}
                              style={{color: "#aed581"}}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <PictureAsPdf/>
                            </a>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={queryResult.data?.singleAnalyteGroupReports?.samples.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </FullPageLayout>
  );
};

export type GraphWithHealthProfileProps = {
  analyte: string;
  title: JSX.Element;
  report: SingleAnalyteGroupReportFieldsFragment;
  profile?: HealthProfileFragment;
}
export const GraphWithHealthProfile = ({analyte, title, report, profile}: GraphWithHealthProfileProps) => {
  const formatAnalyte = useFormatAnalyte()
  const healthRange = profile?.analyteRanges?.find((a) => a.analyte === analyte.replace("result_", "").toLowerCase())
  const graphData =
    (report &&
      (report.points.map(
        (point) =>
          point && {
            date: point.date,
            ...report.keys.reduce(
              (converted, key: string, idx: number) => ({
                ...converted,
                [key]: point.values[idx],
              }),
              {}
            ),
          }
      ) as BarDatum[])) ||
    [];

  const markers = [] as CartesianMarkerProps<DatumValue>[];
  if (healthRange) {
    const markerStyle = (color: string) => ({
      lineStyle: {stroke: color, opacity: 0.5},
      textStyle: {
        fill: color,
        textTransform: "uppercase" as "uppercase",
        fontSize: "8pt",
        fontWeight: 700,
        opacity: 0.5,
      },
    });
    markers.push({
      axis: "y",
      value: healthRange.min,
      legend: "Deficient",
      ...markerStyle("red"),
    });
    if (healthRange.min !== healthRange.low)
      markers.push({
        axis: "y",
        value: healthRange.low,
        legend: "Low",
        ...markerStyle("blue"),
      });

    markers.push({
      axis: "y",
      value: (healthRange.low + healthRange.high) / 2,
      legend: "Target",
      ...markerStyle("green"),
    });

    if (healthRange.high !== healthRange.max)
      markers.push({
        axis: "y",
        value: healthRange.high,
        legend: "High",
        ...markerStyle("blue"),
      });
    markers.push({
      axis: "y",
      value: healthRange.max,
      legend: "Excessive",
      ...markerStyle("red"),
    });
  }
  return <Card style={{width: "100%", height: "500px", textAlign: "center", marginBottom: "1em"}}>
    <CardHeader title={<>
    {title}{profile ? <span style={{color: "gray", fontSize: "0.8em"}}> ({profile.title})</span> : null}
    </>}/>
    <ResponsiveBar
      keys={report.keys}
      data={graphData}
      groupMode="grouped"
      label=""
      valueFormat={(a) => formatAnalyte(analyte, a as number)}
      axisLeft={{
        format: (a) => formatAnalyte(analyte, a as number),
      }}
      indexBy={"date"}
      margin={{top: 50, right: 60, bottom: 140, left: 100}}
      padding={0.3}
      colors={{scheme: "nivo"}}
      markers={markers}
    />
  </Card>
}

export default Analytics;
